import "./Nav.css";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useLocalStorage } from "react-use";
import {
  Card,
  ClickAwayListener,
  Container,
  DialogTitle,
  Divider,
  Grow,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
  Typography,
  useMediaQuery,
  Link as MaterialLink,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import SunMoonSwitch from "./SunMoonSwitch";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import { FormattedMessage } from "react-intl";
import MobileDrawer, { ButtonMobileMenu } from "./MobileDrawer";
import CloseIcon from "@mui/icons-material/Close";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import UniversalNavigator from "../UniversalNavigator";
import LanguageSwitcher from "./LanguageSwitcher";

// const DRAWER_WIDTH = 280;

const buttons = [
  {
    key: 1,
    label: <FormattedMessage id="frontpage.home" defaultMessage="Home" />,
    href: "/",
    Icon: LoyaltyOutlinedIcon,
  },
  {
    key: 2,
    label: <FormattedMessage id="frontpage.news" defaultMessage="News" />,
    href: "/news",
    Icon: NewReleasesOutlinedIcon,
  },
  {
    key: 3,
    label: (
      <FormattedMessage id="frontpage.aboutUs" defaultMessage="About Us" />
    ),
    children: [
      {
        key: 1,
        label: <FormattedMessage id="frontpage.fleet" defaultMessage="Fleet" />,
        children: [],
        href: "/fleet",
        Icon: WorkOutlineOutlinedIcon,
      },
      {
        key: 2,
        label: (
          <FormattedMessage id="frontpage.gallery" defaultMessage="Gallery" />
        ),
        href: "/gallery",
        children: [],
        Icon: ImageIcon,
      },
      {
        key: 3,
        label: (
          <FormattedMessage
            id="frontpage.companyInfo"
            defaultMessage="Company information"
          />
        ),
        href: "/static/company-details",
        children: [],
        Icon: LocationCityOutlinedIcon,
      },
      {
        key: 4,
        href: "/static/company-policy",
        label: (
          <FormattedMessage
            id="frontpage.companyPolicy"
            defaultMessage="Company policy and targets"
          />
        ),
        children: [],
        Icon: PolicyOutlinedIcon,
      },
      {
        key: 5,
        label: (
          <FormattedMessage
            id="frontpage.aboutREM"
            defaultMessage="About REM Offshore"
          />
        ),
        outsideHref: "https://www.remoffshore.no/",
        children: [],
        Icon: BusinessOutlinedIcon,
      },
      {
        key: 6,
        label: (
          <FormattedMessage
            id="frontpage.openPositions"
            defaultMessage="Open Positions"
          />
        ),
        highlighted: true,
        outsideHref:
          "https://cmvarna.crewinspector.com/public/vacancy?referring_office_id=1",
        children: [],
        Icon: GroupWorkIcon,
      },
    ],
    Icon: WorkOutlineOutlinedIcon,
  },
  {
    key: 4,
    label: (
      <FormattedMessage id="frontpage.contactus" defaultMessage="Contact Us" />
    ),
    href: "/contacts",
    Icon: ContactSupportOutlinedIcon,
  },
];

interface RenderMenuItemsProps {
  buttonInfo: ButtonMobileMenu;
  handleEditButton: (key: number, prop: string, value: any) => void;
  odd?: boolean;
}

const RenderMenuItems = ({
  handleEditButton,
  buttonInfo,
  odd = false,
}: RenderMenuItemsProps) => {
  return (
    <MenuList
      autoFocusItem={buttonInfo.open}
      id="composition-menu"
      aria-labelledby="composition-button"
      onKeyDown={(event) => {
        if (event.key === "Tab") {
          event.preventDefault();
          handleEditButton(buttonInfo.key, "open", true);
        } else if (event.key === "Escape") {
          handleEditButton(buttonInfo.key, "open", false);
        }
      }}
    >
      {buttonInfo.children &&
        buttonInfo.children.map((child, i) =>
          child.outsideHref ? (
            <MaterialLink
              key={child.key}
              href={child.outsideHref}
              underline="none"
              target="_blank"
            >
              <MenuItem
                color="secondary"
                onClick={() => {
                  handleEditButton(buttonInfo.key, "open", false);
                }}
              >
                <ListItemIcon>
                  <child.Icon color="secondary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="h6"
                    color={child.highlighted ? "primary" : "textPrimary"}
                  >
                    {child.label}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </MaterialLink>
          ) : (
            <UniversalNavigator
              key={child.key}
              color="secondary"
              style={{ textDecoration: "auto" }}
              to={child.href || "/"}
            >
              <MenuItem
                color="secondary"
                onClick={() => {
                  handleEditButton(buttonInfo.key, "open", false);
                }}
              >
                <ListItemIcon>
                  <child.Icon color="secondary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h6" color="textPrimary">
                    {child.label}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </UniversalNavigator>
          )
        )}
    </MenuList>
  );
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  // backgroundColor: theme.palette.background.paper,
  willChange: "height",
  transition: "height 0.1s",
}));

export const StyledLogo = styled("img")({
  width: 160,
  height: 54,
  verticalAlign: "middle",
  marginLeft: "auto",
  marginRight: "auto",
});

interface Props {
  setTheme: React.Dispatch<React.SetStateAction<string | undefined>>;
  appBarHeight: number;
}

function Nav({ setTheme, appBarHeight }: Props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [themeLS] = useLocalStorage("theme", "light");
  const [checked, setChecked] = React.useState(themeLS === "dark");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
  const [buttonsState, setButtonsState] = useState(
    buttons.map((button) => ({
      ...button,
      ...(button.children && {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        anchorRef: React.useRef<HTMLButtonElement>(null),
        open: false,
      }),
    }))
  );

  const handleEditButton = (key: number, prop: string, value: any) => {
    setButtonsState(
      buttonsState.map((btn) => {
        if (btn.key === key) {
          return {
            ...btn,
            [prop]: value,
          };
        }
        return { ...btn };
      })
    );
  };
  const handleChangeTheme = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(event.target.checked ? "dark" : "light");
    setChecked(event.target.checked);
  };

  return (
    <StyledAppBar
      sx={{
        height: appBarHeight,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      elevation={trigger ? 6 : 2}
      color="default"
    >
      <Container sx={{ height: "100%" }}>
        <Grid
          justifyContent={isMobile ? "space-between" : "space-between"}
          alignItems="center"
          container
          height="100%"
        >
          <Grid item>
            <Box>
              <UniversalNavigator
                style={{ width: "100%", marginLeft: 10 }}
                to="/"
              >
                <StyledLogo src="/pics/rem-logo.svg" alt="logo" />
              </UniversalNavigator>
            </Box>
          </Grid>
          <Grid item>
            <Hidden mdDown>
              <Grid container>
                {buttonsState &&
                  buttonsState.map((buttonInfo) => (
                    <Grid key={buttonInfo.key} item>
                      {buttonInfo.href ? (
                        <UniversalNavigator
                          style={{ textDecoration: "auto" }}
                          color="secondary"
                          to={buttonInfo.href}
                        >
                          <Button size="large" color="secondary">
                            <Box mr={2} ml={2}>
                              <Typography>{buttonInfo.label}</Typography>
                            </Box>
                          </Button>
                        </UniversalNavigator>
                      ) : (
                        <>
                          <Button
                            size="large"
                            color="secondary"
                            ref={buttonInfo.anchorRef}
                            onClick={() => {
                              handleEditButton(
                                buttonInfo.key,
                                "open",
                                !buttonInfo.open
                              );
                            }}
                          >
                            <Box mr={2} ml={2}>
                              <Typography>{buttonInfo.label}</Typography>
                            </Box>
                          </Button>
                          {buttonInfo.children && (
                            <Popper
                              open={!!buttonInfo.open}
                              anchorEl={buttonInfo.anchorRef?.current}
                              role={undefined}
                              placement="bottom"
                              transition
                              modifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 20],
                                  },
                                },
                                {
                                  name: "arrow",
                                  options: {
                                    element: "#arrow", // 5px from the edges of the popper
                                  },
                                },
                              ]}
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{ transformOrigin: "top center" }}
                                >
                                  <Card>
                                    <ClickAwayListener
                                      onClickAway={() => {
                                        handleEditButton(
                                          buttonInfo.key,
                                          "open",
                                          false
                                        );
                                      }}
                                    >
                                      <Box minWidth={320}>
                                        <DialogTitle>
                                          <FormattedMessage
                                            id="frontpage.learnMore"
                                            defaultMessage="Learn more about us"
                                          />
                                          <IconButton
                                            aria-label="close"
                                            onClick={() => {
                                              handleEditButton(
                                                buttonInfo.key,
                                                "open",
                                                false
                                              );
                                            }}
                                            sx={{
                                              position: "absolute",
                                              right: 8,
                                              top: 8,
                                              color: (theme) =>
                                                theme.palette.grey[500],
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </DialogTitle>

                                        <Divider />
                                        <Grid container>
                                          <Grid item xs>
                                            <RenderMenuItems
                                              buttonInfo={buttonInfo}
                                              handleEditButton={
                                                handleEditButton
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </ClickAwayListener>
                                  </Card>
                                </Grow>
                              )}
                            </Popper>
                          )}
                        </>
                      )}
                    </Grid>
                  ))}
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box ml={2} mr={2}>
                    <SunMoonSwitch
                      checked={checked}
                      onChange={handleChangeTheme}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <LanguageSwitcher />
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Box
                onClick={() => {
                  setOpenMenuDrawer(!openMenuDrawer);
                }}
                className={openMenuDrawer ? "open" : ""}
                id="nav-icon1"
              >
                {[1, 2, 3].map((id) => (
                  <span
                    key={id}
                    style={{ background: theme.palette.secondary.main }}
                  ></span>
                ))}
              </Box>
              <MobileDrawer
                openMenuDrawer={openMenuDrawer}
                setOpenMenuDrawer={setOpenMenuDrawer}
                appbarHeight={appBarHeight}
                buttons={buttonsState}
                handleEditButton={handleEditButton}
                checkedTheme={checked}
                handleChangeTheme={handleChangeTheme}
              />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </StyledAppBar>
  );
}

export default Nav;
