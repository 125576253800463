import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Button, Link, Typography } from "@mui/material";
import AppLanguage from "../../const/AppLanguages";
import { useIntl } from "react-intl";
import AppStrings from "../../const/AppStrings";

function LanguageSwitcher(): ReactElement {
  const { pathname } = useLocation();
  const { messages } = useIntl();
  const lang = pathname.slice(1, 3);

  function getMatchingRoute(language: string) {
    const routePath = pathname.substring(3);
    const routeKey = Object.keys(messages).find(
      (key) => messages[key] === routePath
    );
    return routeKey
      ? `/${language}${AppStrings[language][routeKey]}`
      : `/${language}`;
  }

  return (
    <Link
      href={getMatchingRoute(
        lang === "bg" || lang.length === 0
          ? AppLanguage.English
          : AppLanguage.Bulgarian
      )}
      style={{
        textDecoration: "auto",
      }}
    >
      <Button
        sx={{ borderRadius: 28, minWidth: "50px" }}
        size="small"
        color="secondary"
      >
        <Typography variant="h6" color="initial">
          {lang === "bg" || lang.length === 0 ? "🇬🇧" : "🇧🇬"}
        </Typography>
      </Button>
    </Link>
  );
}

export default LanguageSwitcher;
