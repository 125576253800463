import React from "react";
import { IntlProvider } from "react-intl";
import { Route, BrowserRouter } from "react-router-dom";
import AppLanguage from "../../const/AppLanguages";
import AppStrings from "../../const/AppStrings";

interface Props {
  defaultLanguage?: AppLanguage;
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  defaultLanguage,
}) => (
  <BrowserRouter>
    <Route path="/:lang([a-zA-Z]{2})">
      {({ match, location }) => {
        const params = match ? match.params : { lang: undefined };
        const { lang = defaultLanguage || AppLanguage.Bulgarian } = params;
        
        return (
          <IntlProvider
            locale={lang}
            messages={AppStrings[lang] || AppStrings[AppLanguage.Bulgarian]}
          >
            {children}
          </IntlProvider>
        );
      }}
    </Route>
  </BrowserRouter>
);
