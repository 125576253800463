import "./App.css";
import "react-image-lightbox/style.css";
import RoutedMainLayout from "./RoutedMainLayout";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import getTheme from "./theme";
import { useLocalStorage } from "react-use";
import Amplify, { Analytics } from "aws-amplify";
import awsExports from "./aws-exports";
import { LocalizedRouter } from "./components/LocalizedRouter";
import { HelmetProvider } from "react-helmet-async";
Amplify.configure(awsExports);
const isProd = process.env.NODE_ENV === "production";
Analytics.autoTrack("session", {
  enable: isProd,
});
Analytics.autoTrack("pageView", {
  enable: isProd,
});
Analytics.autoTrack("event", {
  enable: isProd,
});

function App() {
  const [theme, setTheme] = useLocalStorage("theme", "light");
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <CssBaseline />
      <HelmetProvider>
        <LocalizedRouter>
          <RoutedMainLayout setTheme={setTheme} />
        </LocalizedRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
