import loadable from "@loadable/component";

const ContactsPage = loadable(() => import("../pages/ContactsPage"));
const FleetPage = loadable(() => import("../pages/FleetPage/FleetPage"));
const GalleryPage = loadable(() => import("../pages/GalleryPage/GalleryPage"));
const AdminPage = loadable(() => import("../pages/AdminPage"));
const MainPage = loadable(() => import("../pages/MainPage/MainPage"));
const NewsPage = loadable(() => import("../pages/NewsPage"));
const PoliciesPage = loadable(() => import("../pages/PoliciesPage"));
const AboutRemOffshore = loadable(
  () => import("../pages/static/AboutRemOffshore")
);
const CompanyDetails = loadable(() => import("../pages/static/CompanyDetails"));
const CompanyPolicy = loadable(() => import("../pages/static/CompanyPolicy"));
const SingleNewsArticlePage = loadable(() => import("../pages/NewsPage/[id]"));

const routes = [
  {
    path: "/gallery",
    Component: GalleryPage,
    pageTitle: {
      id: "frontpage.gallery",
      defaultMessage: "Gallery",
    },
    withAuth: false,
  },
  {
    path: "/fleet",
    Component: FleetPage,
    pageDescription: {
      id: "meta.descriptions.fleet",
      defaultMessage:
        "The Company operates with 11 DP2 offshore vessels – 2 seismic research, 6 PSV and 3 CSV vessels.",
    },
    pageTitle: {
      id: "frontpage.fleet",
      defaultMessage: "Fleet",
    },
    withAuth: false,
  },
  {
    path: "/contacts",
    Component: ContactsPage,
    pageDescription: {
      id: "meta.descriptions.contacts",
      defaultMessage:
        "Address: 269, Primorski Park 536 Str., floor 3, Varna, Bulgaria",
    },
    pageTitle: {
      id: "frontpage.contactus",
      defaultMessage: "Contact Us",
    },
    withAuth: false,
  },
  {
    path: "/news/:id",
    Component: SingleNewsArticlePage,
    pageDescription: {
      id: "meta.descriptions.news",
      defaultMessage:
        "Current and upcoming events, future projects and investments related to the Company's activities.",
    },
    pageTitle: {
      id: "frontpage.news",
      defaultMessage: "News",
    },
    withAuth: false,
  },
  {
    path: "/news",
    pageDescription: {
      id: "meta.descriptions.news",
      defaultMessage:
        "Current and upcoming events, future projects and investments related to the Company's activities.",
    },
    pageTitle: {
      id: "frontpage.news",
      defaultMessage: "News",
    },
    Component: NewsPage,
    withAuth: false,
  },
  {
    path: "/policies",
    pageDescription: {
      id: "meta.descriptions.forus",
      defaultMessage:
        "Rem Management Ltd. is a part of the Norwegian Rem Offshore AS and since 2016 is operating as Manning agent with license №: 2030 / 14.04.2016.",
    },
    pageTitle: {
      id: "frontpage.companyPolicy",
      defaultMessage: "Company policy",
    },
    Component: PoliciesPage,
    withAuth: false,
  },
  {
    path: "/admin",
    Component: AdminPage,
    withAuth: true,
  },
  {
    path: "/static/about-rem-offshore",
    pageTitle: {
      id: "frontpage.aboutREM",
      defaultMessage: "About REM Offshore",
    },
    Component: AboutRemOffshore,
    withAuth: true,
  },
  {
    path: "/static/company-details",
    pageDescription: {
      id: "meta.descriptions.forus",
      defaultMessage:
        "Rem Management Ltd. is a part of the Norwegian Rem Offshore AS and since 2016 is operating as Manning agent with license №: 2030 / 14.04.2016.",
    },
    pageTitle: {
      id: "frontpage.companyInfo",
      defaultMessage: "Company information",
    },
    Component: CompanyDetails,
    withAuth: true,
  },
  {
    path: "/static/company-policy",
    pageDescription: {
      id: "meta.descriptions.forus",
      defaultMessage:
        "Rem Management Ltd. is a part of the Norwegian Rem Offshore AS and since 2016 is operating as Manning agent with license №: 2030 / 14.04.2016.",
    },
    pageTitle: {
      id: "frontpage.companyPolicy",
      defaultMessage: "Company policy",
    },
    Component: CompanyPolicy,
    withAuth: true,
  },
  {
    pageDescription: {
      id: "meta.descriptions.home",
      defaultMessage:
        "Rem Management Ltd. was established  in 2016 with a certificate of registration №: 2030 / 14.04.2016. The Company is located in Varna, Bulgaria",
    },
    pageTitle: {
      id: "frontpage.main",
      defaultMessage: "Home",
    },
    path: "/", // IMPORTANT: THIS SHOULD STAY AT THE BOTTOM
    Component: MainPage,
  },
  {
    pageDescription: {
      id: "meta.descriptions.home",
      defaultMessage:
        "Rem Management Ltd. was established  in 2016 with a certificate of registration №: 2030 / 14.04.2016. The Company is located in Varna, Bulgaria",
    },
    pageTitle: {
      id: "frontpage.main",
      defaultMessage: "Home",
    },
    path: "*", // IMPORTANT: THIS SHOULD STAY AT THE BOTTOM
    Component: MainPage,
  },
];
export default routes;
