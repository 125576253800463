import React from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  Wrapper: React.ComponentClass;
  children: JSX.Element | JSX.Element[];
  [propName: string]: any;
}

const ConditionalWrapper = ({
  condition,
  Wrapper,
  children,
  ...props
}: ConditionalWrapperProps) =>
  // i wrap the children in a div, so that whatever happens we return a single component wrapper and its children
  // this ensures the conditional wrapper always returns JSX.ELEMENT and not JSX.ELEMENT[]
  {
    return condition ? (
      <Wrapper {...props}>{children}</Wrapper>
    ) : (
      <div>{children}</div>
    );
  };

export default ConditionalWrapper;
