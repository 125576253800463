import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app

const theme = (themeMode: string | undefined) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        mode: themeMode && themeMode === "dark" ? "dark" : "light",
        primary: {
          main: "#95d4e7",
        },
        secondary: {
          main: "#0094aa",
        },
        error: {
          main: red.A400,
        },
        background: {
          default: themeMode === "dark" ? "#121212" : "#f7f7f7",
        },
        text: {
          primary: themeMode === "dark" ? "#fff" : "#464444",
        },
      },
      typography: {
        h1: {
          fontSize: "4rem",
          fontWeight: 350
        },
      },
    })
  );
export default theme;
