import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import routes from "../../RoutedMainLayout/routes";

const UniversalNavigator = ({
  to,
  children,
  ...rest
}: {
  to: string;
  [key: string]: any;
}) => {
  const { formatMessage, locale } = useIntl();

  const route = routes.find((r) => r.path === to);

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }
  return route ? (
    <Link {...rest} to={localizeRouteKey(route.path)}>
      {children}
    </Link>
  ) : null;
};

export default UniversalNavigator;
