import { Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import React from "react";
import SunMoonSwitch from "./SunMoonSwitch";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ConditionalWrapper from "../../utils/ConditionalWrapper/ConditionalWrapper";
import MaterialLink from "@mui/material/Link";
import UniversalNavigator from "../UniversalNavigator";
import LanguageSwitcher from "./LanguageSwitcher";

export interface ButtonMobileMenu {
  key: number;
  label?: JSX.Element;
  href?: string;
  Icon?: any;
  children?: ButtonMobileMenu[];
  [key: string]: any;
}

interface Props {
  openMenuDrawer: boolean;
  setOpenMenuDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  appbarHeight: number;
  buttons: ButtonMobileMenu[];
  checkedTheme: boolean;
  handleChangeTheme: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditButton: (key: number, prop: string, value: any) => void;
}

const MobileDrawer = ({
  openMenuDrawer,
  setOpenMenuDrawer,
  appbarHeight,
  buttons,
  checkedTheme,
  handleChangeTheme,
  handleEditButton,
}: Props) => {
  return (
    <SwipeableDrawer
      sx={{
        width: "90%",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: "90%",
          boxSizing: "border-box",
        },
      }}
      disableBackdropTransition
      anchor="right"
      open={openMenuDrawer}
      onClose={() => {
        setOpenMenuDrawer(false);
      }}
      onOpen={() => {
        setOpenMenuDrawer(true);
      }}
    >
      <Box
        sx={{
          overflow: "auto",
          marginTop: `${appbarHeight}px`,
          height: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          flexDirection="column"
          height="100%"
          wrap="nowrap"
        >
          <Grid item>
            <List>
              {buttons.map((listItem) => (
                <div key={listItem.key}>
                  <ConditionalWrapper
                    key={listItem.key}
                    condition={!!listItem.href}
                    Wrapper={UniversalNavigator as any}
                    to={listItem.href}
                    style={{
                      textDecoration: "auto",
                    }}
                  >
                    <ListItemButton
                      onClick={
                        listItem.children
                          ? () => {
                              handleEditButton(
                                listItem.key,
                                "open",
                                !listItem.open
                              );
                            }
                          : () => {
                              setOpenMenuDrawer(false);
                            }
                      }
                    >
                      <ListItemIcon>
                        <listItem.Icon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography color="textPrimary" variant="h5">
                            {listItem.label}
                          </Typography>
                        }
                      />
                      {listItem.children && (
                        <ExpandMore
                          fontSize="large"
                          sx={{
                            transform: `rotate(${listItem.open ? -180 : 0}deg)`,
                            transition: "transform 0.3s",
                          }}
                        />
                      )}
                    </ListItemButton>
                  </ConditionalWrapper>
                  {listItem.children && (
                    <Collapse in={listItem.open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {listItem.children.map((child) => (
                          <ConditionalWrapper
                            key={child.key}
                            condition={!!child.href || !!child.outsideHref}
                            Wrapper={
                              child.outsideHref
                                ? MaterialLink
                                : (UniversalNavigator as any)
                            }
                            to={child.outsideHref ? undefined : child.href}
                            underline={child.outsideHref ? "none" : undefined}
                            href={
                              child.outsideHref ? child.outsideHref : undefined
                            }
                            target={child.outsideHref ? "_blank" : undefined}
                            style={{
                              textDecoration: "auto",
                            }}
                          >
                            <ListItemButton
                              onClick={() => {
                                setOpenMenuDrawer(false);
                              }}
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon>
                                <child.Icon color="primary" />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography
                                    color={
                                      child.highlighted
                                        ? "secondary"
                                        : "textPrimary"
                                    }
                                    variant="h5"
                                  >
                                    {child.label}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </ConditionalWrapper>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </div>
              ))}
            </List>
          </Grid>
          <Grid item>
            <Box mb={2}>
              <Grid container justifyContent="space-around" spacing={4}>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Grid item>
                      <Typography variant="h5">Theme:</Typography>
                    </Grid>
                    <Grid item>
                      <SunMoonSwitch
                        checked={checkedTheme}
                        onChange={handleChangeTheme}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    flexDirection="column"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h5">Language:</Typography>
                    </Grid>
                    <Grid item>
                      <LanguageSwitcher />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

export default MobileDrawer;
