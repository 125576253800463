import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StyledLogo } from "../Nav";
import FacebookOutlinedIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/system";

interface Props {}

const socialButtons = [
  {
    Icon: FacebookOutlinedIcon,
    text: "Facebook",
    link: "https://www.facebook.com/Remmanagement2016",
  },
  {
    Icon: InstagramIcon,
    text: "Instagram",
    link: "https://www.instagram.com/remoffshore",
  },
  {
    Icon: LinkedInIcon,
    text: "LinkedIn",
    link: "https://www.linkedin.com/company/rem-management/",
  },
];

const documents = [
  {
    messageID: "footer.useful_links.certification",
    default: "Certification",
    path: "/docs/certification.pdf",
  },
  {
    messageID: "footer.useful_links.company_policy",
    default: "Company Policy",
    path: "/docs/company_policy.docx",
  },
  {
    messageID: "footer.useful_links.attestation_of_compliance",
    default: "Attestation of Compliance",
    path: "/docs/attestation_of_compliance.pdf",
  },
];

const Footer = (props: Props) => {
  const theme = useTheme();
  const matchBP = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <footer style={{ marginTop: "calc(5% + 60px)", bottom: 0 }}>
      <Paper>
        <Container>
          <Grid
            spacing={matchBP ? 12 : 4}
            justifyContent="space-between"
            container
          >
            <Grid alignSelf="center" item>
              <Box>
                <StyledLogo src="/pics/rem-logo.svg" alt="logo" />
              </Box>
            </Grid>
            <Grid item xs={12} md>
              <Typography variant="h5">
                <FormattedMessage
                  defaultMessage="About us"
                  id="footer.about_us_heading"
                />
              </Typography>
              <Box
                width={theme.spacing(20)}
                mt={1}
                borderTop={`10px solid ${theme.palette.primary.main}`}
              />

              <br />
              <Typography>
                <FormattedMessage
                  id="footer.about_us"
                  defaultMessage="Rem Management Ltd, established in 2016, is part of the Norwegian Rem Offshore AS. Rem Management Ltd. has an official license to operate as a Manning Agency, which has been acquired in accordance with the requirements of Bulgarian legislation and has an indefinite registration."
                />
              </Typography>
            </Grid>

            <Grid item xs={12} md>
              <Typography variant="h5">
                <FormattedMessage
                  defaultMessage="Useful links"
                  id="footer.useful_links"
                />
              </Typography>

              <Box
                width={theme.spacing(20)}
                mt={1}
                borderTop={`10px solid ${theme.palette.primary.main}`}
              />
              <br />

              {documents.map((document) => (
                <Typography
                  key={document.messageID}
                  style={{
                    cursor: "pointer",
                  }}
                  gutterBottom
                >
                  <Link
                    href={document.path}
                    target="_blank"
                    style={{ color: "inherit" }}
                  >
                    <FormattedMessage
                      id={document.messageID}
                      defaultMessage={document.default}
                    />
                  </Link>
                  <br />
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Box m={4}>
            <Divider />
          </Box>
          <Box pb={4}>
            <Typography align="center">
              Copyright {new Date().getFullYear()} | Designed by{" "}
	       <Link color="secondary" href="https://www.enter21.io">
                Enter 21
              </Link>
            </Typography>
            <Grid container justifyContent="center">
              {socialButtons &&
                socialButtons.map((button, i) => (
                  <Link
                    key={i}
                    color="secondary"
                    href={button.link}
                    target="_blank"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    <IconButton aria-label={button.text} size="large">
                      <button.Icon />
                    </IconButton>
                  </Link>
                ))}
            </Grid>
          </Box>
        </Container>
      </Paper>
    </footer>
  );
};

export default Footer;
