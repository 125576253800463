import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter,
} from "react-router-dom";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import routes from "./routes";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./RoutedLayout.css";
import { useEffect } from "react";
import { useWindowScroll } from "react-use";
import { useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

const APP_BAR_HEIGHT = 60;

function _ScrollToTop({ children }: any) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return children;
}
const ScrollToTop = withRouter(_ScrollToTop);

interface Props {
  setTheme: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AnimatedRoutes = ({ appBarHeight }: Props & { appBarHeight: number }) => {
  let location = useLocation();

  function localizeRoutePath(path?: string | string[]) {
    switch (typeof path) {
      case "undefined":
        return undefined;
      case "object":
        return path.map((key) => `/${locale}` + formatMessage({ id: key }));
      default:
        const isFallbackRoute = path === "*";
        return isFallbackRoute
          ? path
          : `/${locale}` + formatMessage({ id: path });
    }
  }

  const { formatMessage, locale } = useIntl();
  return (
    <TransitionGroup>
      <CSSTransition timeout={250} classNames="fade" key={location.key}>
        <Switch location={location}>
          {routes &&
            routes.map((route) => (
              <Route
                exact
                key={route.path}
                path={localizeRoutePath(route.path)}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: appBarHeight,
                    bottom: 0,
                  }}
                >
                  {route.pageTitle || route.pageDescription ? (
                    <Helmet>
                      {route.pageTitle && (
                        <title>
                          {formatMessage({
                            id: "frontpage.banerTitle",
                            defaultMessage: "Rem Management",
                          })}{" "}
                          -{" "}
                          {formatMessage({
                            id: route.pageTitle?.id,
                            defaultMessage: route.pageTitle?.defaultMessage,
                          })}
                        </title>
                      )}

                      <meta
                        name="keywords"
                        content={
                          locale === "bg"
                            ? "офшор, екипаж, менинг, наемане, посредическа, агент, кораб, корабен, Рем, море, петрол, газ, сеизмик, ПСВ, ветрогенератор, перка, шутинг весел, сорс весел, съплай, съпорт, вятър, изследователски"
                            : "offshore, crew, manning, hire, intermediate, agent, crewing agent, ship, vessel, Rem, sea, oil and gas, seismic, PSV, wind farm, wind turbine, wind, shooting vessel, source vessel, nod-layer, research, support, supply, construction, walk to work, gangway, offshore crane"
                        }
                      />
                      <html lang={locale} />
                      <meta http-equiv="Content-Language" content={locale} />
                      {route.pageDescription && (
                        <meta
                          name="description"
                          content={formatMessage({
                            id: route.pageDescription?.id || "",
                            defaultMessage:
                              route.pageDescription?.defaultMessage,
                          })}
                        />
                      )}
                    </Helmet>
                  ) : (
                    <Helmet>
                      <title>Rem Management</title>
                    </Helmet>
                  )}

                  <route.Component />
                  <Footer />
                </div>
              </Route>
            ))}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const RoutedMainLayout = ({ setTheme }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { y } = useWindowScroll();
  const appBarHeight = isMobile ? APP_BAR_HEIGHT : 100 - Math.min(y, 40);
  const contentOffset = isMobile ? APP_BAR_HEIGHT : 100;
  return (
    <Router>
      <div>
        <nav>
          <Nav setTheme={setTheme} appBarHeight={appBarHeight} />
        </nav>

        <main>
          <ScrollToTop>
            <AnimatedRoutes appBarHeight={contentOffset} setTheme={setTheme} />
          </ScrollToTop>
        </main>
      </div>
    </Router>
  );
};

export default RoutedMainLayout;
